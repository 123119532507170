//! СКРОЛЛИНГ
export function smoothScrollToElement(selector, fixedHeaderHight) {
  //? текущая позиция
  let currentPosition = pageYOffset;

  //? определяем позицию элемента
  let pos;
  if (typeof selector === "undefined") {
    pos = 0;
  } else {
    pos =
      document.querySelector(selector).getBoundingClientRect().top +
      currentPosition -
      fixedHeaderHight;
  }
  //? скролим в позицию
  scrollTo({
    top: pos,
    left: 0,
    behavior: "smooth",
  });
}
