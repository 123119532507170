import { smoothScrollToElement } from "./smoothScrollToElement";

// высота хедера
const fixedHeaderHight = 60;

// бургер и ссылки
const burger = document.querySelector(".header__burger");
const headerLinks = document.querySelector(".header__links");

// массив ссылок сайта
let links = Array.from(headerLinks.children);

//? БУРГЕР
function burgerHandler(e) {
  if (e.target.classList.contains("header__burger-mask")) {
    headerLinks.classList.toggle("open");
    burger.classList.toggle("open");
  } else {
    headerLinks.classList.remove("open");
    burger.classList.remove("open");
  }
}

//? ПЕРЕХОДЫ ПО ССЫЛКАМ
function headerLinksHandler(e) {
  e.preventDefault();
  const href = e.target.getAttribute("href");
  if (href != null) {
    smoothScrollToElement(href, fixedHeaderHight);
  }

  // добавляем активный класс
  const activeLink = document.querySelector(".active");
  if (activeLink) {
    activeLink.classList.remove("active");
  }
  e.target.classList.add("active");
}

//? РАССТАВЛЕНИЕ АКТИВНЫХ ССЫЛОК ПРИ ПРОКРУТКЕ
function activeOnScroll() {
  links.forEach((el) => {
    const block = document.querySelector(el.getAttribute("href"));
    // позиция верха блока
    const elementTopPosition = block.getBoundingClientRect().top + pageYOffset;
    // позиция низа блока
    const elementBottomPosition =
      block.getBoundingClientRect().bottom + pageYOffset;
    // позиция центра окна
    const windowCenterPosition = pageYOffset + innerHeight / 2;
    // если верх блока выше центра, и одновременно низ ниже центра,
    // то отмечаем соответствующий пункт меню цветом
    if (
      elementTopPosition < windowCenterPosition &&
      elementBottomPosition > windowCenterPosition
    ) {
      // перебираем все ссылки
      // у ненужных удаляем active
      // нужным прибавляем
      links.forEach((link) => {
        if (link.getAttribute("href") === el.getAttribute("href")) {
          link.classList.add("active");
        } else {
          link.classList.remove("active");
        }
      });
    }
  });
}

//? ПОЯВЛЕНИЕ СТРЕЛКИ НАВИГАЦИИ
function arrowShow() {
  if (window.scrollY >= 200) {
    if (
      !(
        navigator.userAgent.includes("YaBrowser") &&
        navigator.userAgent.includes("Mobile")
      )
    ) {
      document.querySelector(".up-btn").classList.add("active_btn");
    }
  } else {
    document.querySelector(".up-btn").classList.remove("active_btn");
  }
}

//! СОБЫТИЯ
document.addEventListener("DOMContentLoaded", function (event) {
  //? БУРГЕР
  document.body.addEventListener("click", burgerHandler);

  //? ПЕРЕХОДЫ ПО ССЫЛКАМ
  headerLinks.addEventListener("click", headerLinksHandler);

  //? В НАЧАЛЕ СТРАНИЦЫ СТАВИМ ПЕРВУЮ ССЫЛКУ АКТИВНОЙ
  activeOnScroll();

  //? ПОЯВЛЕНИЕ СТРЕЛКИ НАВИГАЦИИ
  //? РАССТАВЛЯЕМ АКТИВНЫЕ ССЫЛКИ
  window.addEventListener("scroll", () => {
    arrowShow();
    activeOnScroll();
  });

  //? КЛИК ПО ЛОГО
  document.querySelector(".header__logo").addEventListener("click", (e) => {
    smoothScrollToElement();
  });

  //? КЛИК ПО СТРЕЛКЕ ВВЕРЗ
  document.querySelector(".up-btn__mask").addEventListener("click", (e) => {
    smoothScrollToElement();
  });

  //? КЛИК ПО КНОПКЕ
  document.querySelector("#become_a_client").addEventListener("click", (e) => {
    smoothScrollToElement(".contacts", fixedHeaderHight);
  });
  document.querySelector("#cowork").addEventListener("click", (e) => {
    smoothScrollToElement(".contacts", fixedHeaderHight);
  });
});
